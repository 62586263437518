const Percentage = ({ index, percentage }) => {
  if (percentage > 0)
    return (
      String.fromCharCode(index + 65) +
      "(" +
      Math.round(percentage * 100 * 10) / 10 +
      "%) "
    );
  else return "";
};

export default Percentage;
