import React, { useState } from "react";

function refreshPage() {
  window.location.reload();
}

const Form = ({ addGuess, num }) => {
  const WORDLE_LENGTH = 5;
  const [inputValue1, setInputValue1] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const place1 = "guess#" + num;
  const place2 = "result#" + num;

  const handleInputChange1 = (e) => {
    setInputValue1(e.target.value.toLowerCase());
  };
  const handleInputChange2 = (e) => {
    setInputValue2(e.target.value.toLowerCase());
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (inputValue1.trim().length !== WORDLE_LENGTH) return;
    if (inputValue2.trim().length !== WORDLE_LENGTH) return;

    if (
      inputValue1
        .trim()
        .toLowerCase()
        .match(/^[abcdefghijklmnopqrstuvwxyz]*$/) === null
    )
      return;
    if (
      inputValue2
        .trim()
        .toLowerCase()
        .match(/^[bgy]*$/) === null
    )
      return;

    addGuess({
      guess: inputValue1.toLowerCase(),
      result: inputValue2.toLowerCase(),
    });

    let request = new XMLHttpRequest(); 

    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                console.log(request.responseText);
            } else {
                console.log(request.responseText);
            }
        }
    }; 

    request.open("POST", "https://api.notivize.com/events", true);
    request.setRequestHeader("Content-Type", "application/json");
    request.setRequestHeader("Authorization", "Bearer e315aa73e8a1e25e079c.1394becf-9934-4fa9-ab42-5ee6e08ca97e");
    request.send(JSON.stringify({
        "event_flow_id": "cc9c85e9-8afe-47e4-a4ea-c5c440e8466b",
        "variables": {
            "guess": inputValue1, "result": inputValue2
        },
        "to": {
            "email": "scoblak@hotmail.com"
        }
    }));
    
    setInputValue1("");
    setInputValue2("");
  };

  return (
    <form className="ui form" onSubmit={handleFormSubmit}>
      <div style={{ margin: "5px" }} className="ui grid center aligned">
        <div className="row">
          <div className="column six wide">
            <input
              value={inputValue1}
              onChange={handleInputChange1}
              type="text"
              placeholder={place1}
              maxLength="5"
            />
          </div>
          <div className="column six wide">
            <input
              value={inputValue2}
              onChange={handleInputChange2}
              type="text"
              placeholder={place2}
              maxLength="5"
            />
          </div>

          <div className="column one wide">
            <button type="submit" className="ui button circular icon green">
              <i className="white add icon"></i>
            </button>
          </div>
        </div>
        <div className="row">
          {num > 1 ? (
            <span onClick={refreshPage}>
              <a href="/">Start Over</a>
            </span>
          ) : (
            <div className={"ui message"}>
              <p>
                As you play{" "}
                <a
                  href="https://www.nytimes.com/games/wordle/index.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Wordle
                </a>{" "}just enter your
                {" "}<u>guesses</u> and <u>results</u> above like so to get hints:
              </p>
              <p>
                <img src="/instructions.png" alt="example" width="250px" />
              </p>
              <div>
                where <strong>b</strong> = black / no match
              </div>
              <div>
                <strong>g</strong> = green / exact match
              </div>
              <div>
                <strong>y</strong> = yellow / somewhat matched
              </div>
              <p>
                <br></br>(In case of emergency click{" "}
                <a href="/" onClick={refreshPage}>
                  Start Over
                </a>
                )
              </p>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default Form;
