import React from "react";

const Tile = ({ guess, result }) => {
	var color = [];
	for (var i = 0; i < guess.length; i++) {
		var c;
		if(result[i] === "b")
			c = "black";
		else if(result[i] === "g")
			c = "green";
		else if(result[i] === "y")
			c = "yellow";
		color.push("ui button tiny " + c);
	}
    return (
      <div style={{ margin: "5px" }}>
        <button class={color[0]}>{guess[0].toUpperCase()}</button>
        <button class={color[1]}>{guess[1].toUpperCase()}</button>
        <button class={color[2]}>{guess[2].toUpperCase()}</button>
        <button class={color[3]}>{guess[3].toUpperCase()}</button>
        <button class={color[4]}>{guess[4].toUpperCase()}</button>
      </div>
    );
};

export default Tile;
