import React, { useState } from "react";
import HttpsRedirect from "react-https-redirect";
import hints from "./apis";

import Form from "./components/Form";
import Section from "./components/Section";
import WordList from "./components/WordList";
import LetterList from "./components/LetterList";
import History from "./components/History";

const appTitle = "Wordle Solver";

const App = () => {
  const [possibleWordsList, setPossibleWordsList] = useState([]);
  const [letterProbabilityList, setLetterProbabilityList] = useState([]);
  const [guess, setGuess] = useState([]);

  const addGuess = async (t) => {
    guess.push(t);
    setGuess(guess);

    async function fetchData() {
      const p = await hints.post("/hints", {
        turn: guess,
      });
      setPossibleWordsList(p.data.possibleWords.sort());
      setLetterProbabilityList(p.data.letterProbability);
    }

    fetchData();
  };

  return (
    <HttpsRedirect>
      <div className="ui container center aligned">
        <Section>
          <h1>{appTitle}</h1>
        </Section>

        <Section>
          <History guessList={guess} />
          <Form addGuess={addGuess} num={guess.length + 1} />
          {possibleWordsList.length ? (
            <>
              <div className={"ui message"}>
                <h4>Best Letters To Guess</h4>
                <LetterList list={letterProbabilityList} />
              </div>
              <div className={"ui message"}>
                <h4>
                  {"Possible Solutions (" + possibleWordsList.length + ")"}
                </h4>
                <WordList list={possibleWordsList} probs={letterProbabilityList} />
              </div>
            </>
          ) : (
            <>
              {guess.length ? (
                <div className={"ui message"}>
                  <h4>Sorry, no possible solutions found!</h4>
                  <p>Maybe a typo?</p>
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </Section>
      </div>
    </HttpsRedirect>
  );
};

export default App;
