import React from "react";
import Tile from "./Tile";

const History = ({ guessList }) => {
  const renderedList = guessList.map((item, index) => (
    <Tile guess={item.guess} result={item.result} />
  ));

  return (
      <div>{renderedList}</div>
  );
};

export default History;
