import React from "react";
import Possible from "./Possible";

function isIsogram (str) {
    return !/(.).*\1/.test(str);
}

const WordList = ({ list, probs }) => {
/*
  var strength = [];
  for(var x = 0; x < list.length; x++) {
  	var suml = 0;
  	for(var y = 0; y < list[x].length; y++) {
  		suml += probs[list[x].charCodeAt(y)-97];
	}
	strength.push(suml);
  }

  var templist = [];
  for (var j = 0; j < strength.length; j++)
    templist.push({ strength: strength[j], list: list[j] });
  templist.sort(function (a, b) {
    return a.strength >= b.strength ? -1 : a.list === b.list ? 0 : 1;
  });

  for (var k = 0; k < templist.length; k++) {
    list[k] = templist[k].list;
    strength[k] = templist[k].strength;
  }
*/
  var templist1 = [];
  var templist2 = [];

  for (var i = 0; i < list.length; i++) {
    if(isIsogram(list[i])) {
      templist1.push(list[i]);
    } else {
      templist2.push(list[i]);
    }
  }

  list = templist1.concat(templist2);

  const renderedList = list.map((item) => <Possible word={item} />);

  return (
      <div>{renderedList}</div>
  );
};

export default WordList;
