import React from "react";
import Percentage from "./Percentage";

const LetterList = ({ list }) => {
  var alpha = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25,
  ];

  var templist = [];
  var finallist =[];
  for (var j = 0; j < alpha.length; j++)
    templist.push({ alpha: alpha[j], list: list[j] });
  templist.sort(function (a, b) {
    return a.list >= b.list ? -1 : a.alpha === b.alpha ? 0 : 1;
  });

  for (var k = 0; k < templist.length; k++) {
    finallist[k] = templist[k].list;
    alpha[k] = templist[k].alpha;
  }

  const renderedList = finallist.map((item, index) => (
    <Percentage index={alpha[index]} percentage={item} />
  ));

  return (
      <div>{renderedList}</div>
  );
};

export default LetterList;
